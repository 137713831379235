<template>
	<div class="table-responsive">
		<div class="row justify-content-between">
      <div class="col-9">
        <h4 class="text-center">Espaços disponíveis</h4>
      </div>
      <div class="col-3 mb-1">
        <button class="btn btn-primary" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-funnel" viewBox="0 0 16 16">
            <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2h-11z"/>
          </svg>
        </button>
      </div>
    </div>
    <div class="collapse" id="collapseExample">
      <div class="card card-body">
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-4 mt-1">
              <InputSelect collection="locationGroups" object="locationGroup" fieldKey="id" fieldText="description" v-bind:current="groupSelected" @select="(sel) => groupSelected = sel" rows="200"/>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-8 mt-1">
              <!-- <input v-model="param" placeholder="Digite para pesquisar" class="form-control" @keypress="callSearch"> -->
              <InputSelect collection="locations" object="location" fieldKey="id" fieldText="name" @select="(sel) => location_id = sel" rows="200"/>
            </div>
            <div class="col-auto mr-auto col-md-8 mt-1">
              <input type="date" class="form-control" v-model="availableDate" >
            </div>
            <div class="col-auto col-md-3 mt-1">
              <a type="button" @click="search()" class="btn btn-dark">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card mb-1" v-for="record in records" :key="record.id">
      <div :class="`row card-body ${this.getStatusClass(record)}`">
        <div class="col-9">
          <p class="card-text"><strong>{{ record.location.name }}</strong><br/>{{ this.weekDays[record.weekday] }} / {{ record.allday ? 'Dia todo' : `${record.starttime} - ${record.endtime}` }}</p>
        </div>
        <div class="col-3">
          <button v-if="this.getStatusClass(record) == 'available'" type="button" class="btn btn-light" @click="reserve(record.id)" data-toggle="tooltip" title="Reservar">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-calendar2-check" viewBox="0 0 16 16">
              <path d="M10.854 8.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708 0z"/> <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H2z"/> <path d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V4z"/>
            </svg>
          </button>
        </div>
      </div>
    </div>
	</div>
  
</template>

<script>
import { sAlert, sDialog } from '../utils/jalerts';
import ApiAxios from '../../services/api-axios';
import moment from 'moment';
import InputSelect from '../commons/InputSelect.vue';

export default {
	components: {
		InputSelect,
	},
  data() {
    return {
			showModal: false,
			reservationSelected: null,
			availableDate: moment().format('YYYY-MM-DD'),
			location_id: 0,
      groupSelected: null,
			records: [],
			strFields: '',
			page: 1,
			size: 10,
			pages: 0,
			sort: '',
			param: '',
			weekDays: ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'],
      statusMap: { 'available': 'Disponível', 'prebooked': 'Pré-reservado', 'reserved': 'Reservado', 'unavailable': 'Indisponível', 'canceled': 'Cancelado' }
		};
  },
  watch: {
    activityDate() {
      this.fetchGetData();
    }
  },
  computed: {
		filters() {
      let filters = {};
			if (this.param) filters.name = this.param;
      if (this.location_id) filters.location_id = this.location_id;
      
      return filters;
		},
	},
  methods: {
		callSearch(e) {
			if (e.keyCode === 13) this.search();
		},
		search() {
			if(this.page === 1) { return this.fetchGetData(); }
			this.page = 1;
		},
    async fetchGetData() {
			this.$root.loadingVisible = true;
			ApiAxios.get(`locations/availables`, {
				startdate: this.availableDate,
        location_group_id: this.groupSelected,
				params: {
					page: 0,
					sort: this.sort,
					size: 100,
					filter: this.filters,
				}
			})
			.then((res) => {
				this.records = res.data.availabilities;
				this.pages = res.data.totalPages;
				this.$root.loadingVisible = false;
			})
			.catch((e) => {
				this.$root.loadingVisible = false;
				if (e.response.status == 403) {
					this.$router.back();
					return sAlert({ type: 'warning', text: 'Ops, ' + e.response.data });  
				}
				sAlert({ type: 'danger', text: 'Ops, houve um erro: ' + e.response.data });
			});
		},
		getStatusClass(record) {
			if (record.unavailable) return 'unavailable';
			if (record.LocationReservations?.[0]?.status == 'prebooked') return 'prebooked';
			if (record.LocationReservations?.[0]?.status == 'reserved') return 'reserved';
			return 'available';
		},
		getStatusDescription(record) {
			if (record.unavailable) return 'Indisponível';
			if (record.LocationReservations?.[0]?.status == 'prebooked') return 'Pré-reservado';
			if (record.LocationReservations?.[0]?.status == 'reserved') return 'Reservado';
			return 'Disponível';
		},
    reserve(location_availability_id) {
      let that = this;
      sDialog({
				text: `Deseja efetuar a reserva?<br><br><small>Sujeito a aprovação do clube.</small>`,
				confirmFunction() {
					that.sendReserve(location_availability_id);
				},
			})
    },
    sendReserve(location_availability_id) {
      let locationReservation = {
        location_availability_id,
        startdate: this.availableDate
      }
      this.$root.loadingVisible = true;
      ApiAxios.post(`associate/me/locationReservations`, { locationReservation })
        .then(() => {
          this.$root.loadingVisible = false;
          sAlert({ type: 'success', text: 'Solicitação enviada' });
          this.search();
        })
        .catch((e) => {
          this.$root.loadingVisible = false;
          if (e.response.status == 403) {
            return sAlert({ type: 'warning', text: 'Ops, ' + e.response.data });  
          }
          sAlert({ type: 'danger', text: 'Ops, houve um erro: ' + e.response.data });
        });
    }
	},
	created () {
		this.fetchGetData();
	}
}
</script>

<style scoped>
.unavailable {
	background-color: rgba(255, 46, 46, 0.3); 
}
.prebooked {
	background-color: rgba(237, 245, 123, 0.3); 
}
.reserved {
	background-color: rgba(84, 100, 246, 0.3); 
}
.available {
	background-color: rgba(142, 250, 158, 0.3); 
}
</style>